import React, {useState, useEffect, useRef} from "react";
import ProfilePic from '../images/portfolio_pic.jpeg';
import '../styles/About.css'



export default function About()
{

   
    const greeting = "Hi, I'm Julian.";
    const [ name, setName ] = useState('');
    const [ index, setIndex ] = useState(0);
    const [ portaitClass, setPortraitClass ] = useState("");
    const mediaQuery = '(max-width: 1024px)';
    let mediaQueryList = useRef(window.matchMedia(mediaQuery).matches);

    /* on resize */

    useEffect(() => {

        window.addEventListener('resize', () => {
            mediaQueryList.current = window.matchMedia(mediaQuery).matches;
            if (mediaQueryList.current) {
                setPortraitClass("hide_portrait");
                
            } else {
                setPortraitClass("show_portrait");
        
            }
        });
    }, [portaitClass]);

    /* Initial Render */

    useEffect(() => {
        const display_name = () => {
        
            let interval = window.setInterval(function(){
    
                if(index < greeting.length){
                    setName(e => e + greeting[index]);
                    setIndex(i => i + 1);
                }
                
    
                clearInterval(interval);
                
            }, 175);
        }
        display_name();
        if (mediaQueryList.current) {
            setPortraitClass("hide");
        
        } else {
            setPortraitClass("");
    
        }
    },[name, index]);

    return(
        <div id="about-cntr">
            
            <div id="about">
                <div id="intro">
                    {/* <span id="first"></span>
                    <span style={{color: "rgb(42, 201, 138)"}} id="second"></span> */}
                    <span id="first">{name}</span>
                    <span id="blinker">|</span>
                </div>
                
                <div id="about-desc">
                    <span>I am a developer based out of Washington State. <br/>I have a profound interest in machine learning, <br/> web development, and everything in between.</span>
                </div>
            </div>

            <div id="portrait" className={portaitClass}>
                <div id="card">
                    <div id="pic">
                            <img src={ProfilePic} alt="me" id="pic-portrait"></img>
                    </div>
                    <div id="card-info">
                        <span className="card-name">Julian</span>
                        <span className="card-name">Diaz</span>
                        <div id="divider"></div>
                        <span id="position">Entry-level Software Engineer</span>
                    </div>
                </div>
            </div>

            
        </div>
        
    )
}