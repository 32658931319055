import React, { useState } from "react";
import '../styles/Contact.css';
import Label from "./Label";
import emailjs from '@emailjs/browser';

export default function Contact(){

    const [fn, setFN] = useState("");
    const [ln, setLN] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    /* ensuring all fields are filled in before sending an email*/
    let disabledButton = true;

    if(fn.length !== 0 && ln.length !== 0 && email.length !== 0 && message.length !== 0){
        disabledButton = false;
    }


    /* user action to send email */
    const send_email = (e) => {
        disabledButton = true;
        e.preventDefault();        
        let params = {

            "firstName" : fn,
            "lastName" : ln,
            'email' : email,
            'message' : message,

        }

        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, params, process.env.REACT_APP_PUBLIC_KEY)
        .then(alert("Email was sent successfully!")).catch(e => alert("There was a problem relaying your message. Please try again."));
        
    }

    return(
        <div className="contact-cntr">
            
            <div id="my-contact">

                <h1 id="contact-info-title">Contact Info</h1>
                <Label title={"Name:"} value={"Julian"} path={"M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"}/>
                <Label title={"Email:" } value={"Juliandiaz.2727@gmail.com"} path={"M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"} />
                <Label title={"Phone:" } value={"(509)-398-4626"} path={"M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z"}/>
            
            </div>

            <div id="contact-me">

                <form className="email-form" name="contact">

                    <div className="email-fields">

                        <label>First name: </label>
                        <input onChange={(e) => setFN(e.target.value)} className="name-fields"></input>
                        <label>Last name: </label>
                        <input onChange={(e) => setLN(e.target.value)} className="name-fields"></input>

                   </div>

                   <div className="email-fields">

                        <label>Email: </label>
                        <input onChange={(e) => setEmail(e.target.value)} className="name-fields"></input>

                   </div>

                   <div className="email-fields message">

                        <label>Message: </label>
                        <textarea className="message-box" onChange={(e) => setMessage(e.target.value)}></textarea>

                   </div>

                    <button className="submit-email" disabled = {disabledButton} onClick={send_email}>Send</button>

                </form>

            </div>

        </div>
    )
}