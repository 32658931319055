import React, { useState } from "react";
import RealEstate from '../images/real_estate.png';
import Dncnn from '../images/dncnn.png';
import PokeAPI from '../images/poke_api.png';
import '../styles/Projects.css';

export default function Projects(){
    const [imgIndex, setImgIndex] = useState(0);

    const handlePrev = () => {
        var images = document.querySelectorAll('.slider');
        console.log(imgIndex);
        if(imgIndex === 0){
            images[0].setAttribute("style", "transform: translateX(-400%)");
            images[1].setAttribute("style", "transform: translateX(-200%)");
            images[2].setAttribute("style", "transform: translateX(0)");
            setImgIndex(1);
        }
        else if(imgIndex === 1){
            images[0].setAttribute("style", "transform: translateX(-400%)");
            images[1].setAttribute("style", "transform: translateX(0)");
            images[2].setAttribute("style", "transform: translateX(200%)");
            setImgIndex(2);
        }
        else{
            images[0].setAttribute("style", "transform: translateX(0)");
            images[1].setAttribute("style", "transform: translateX(200%)");
            images[2].setAttribute("style", "transform: translateX(400%)");
            setImgIndex(0);
        }
    }

    const handleNext = () => {
        var images = document.querySelectorAll('.slider');
        console.log(imgIndex);
        console.log(images);
        if(imgIndex === 0){
            images[0].setAttribute("style", "transform: translateX(-200%)");
            images[1].setAttribute("style", "transform: translateX(0)");
            images[2].setAttribute("style", "transform: translateX(200%)");
            setImgIndex(2);
        }
        else if(imgIndex === 2){
            images[0].setAttribute("style", "transform: translateX(-400%)");
            images[1].setAttribute("style", "transform: translateX(-200%)");
            images[2].setAttribute("style", "transform: translateX(0)");
            setImgIndex(1);
        }
        else{
            images[0].setAttribute("style", "transform: translateX(0)");
            images[1].setAttribute("style", "transform: translateX(200%)");
            images[2].setAttribute("style", "transform: translateX(400%)");
            setImgIndex(0);
        }
        
    }

    return(
        <div className="project-cntr">

            <div className="project-card">
                <div className="slider" style={{transform: "translateX(0)"}}>
                    <img src={RealEstate} className="project-img" alt="Real Estate website"></img>
                    <span className="title">Real Estate website</span>
                    <span className="project-desc">A real estate website that investors could use to find listings to find the best ROI. <br/> Tech stack includes: React, Express/Node.js, and Python</span>
                </div>
                <div className="slider" style={{transform: "translateX(200%)"}}>
                    <img src={Dncnn} className="project-img" alt="DNCNN website"></img>
                    <span className="title">DnCNN Image Denoiser</span>
                    <span className="project-desc">A Denoising Convolutional Neural Network that allows a user to denoise any image. <br/> Tech stack includes: Python/PyTorch</span>
                </div>
                <div className="slider" style={{transform: "translateX(400%)"}}>
                    <img src={PokeAPI} className="project-img" alt="Pokemon API website"></img>
                    <span className="title">Pokemon finder using a RESTful API</span>
                    <span className="project-desc">A small web application that allows users to search for pokemons using a RESTful API<br/> Tech stack includes: HTML/CSS, vanilla javascript</span>
                </div>
                <button className="card-btn" id="prev-btn" onClick={handlePrev}>{"<"}</button>
                <button className="card-btn" id="next-btn" onClick={handleNext}>{">"}</button>
            </div>
        </div>
    )
}