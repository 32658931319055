import React from 'react'
import '../styles/Label.css'

export default function Label(props) {
  return (
    <div className='label-section'>
        
        <span className='titles'>
            <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root icons" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                <path d={props.path} className='path'></path>
            </svg>
            {props.title}
        </span>
        <span className='values'>{props.value}</span>
    </div>
  )
}

