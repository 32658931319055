import React from "react";
import '../styles/Skills.css';
import PyTorch from '../images/pytorch.png';
import C from '../images/C.png';
import Cuda from '../images/Cuda.png';
import Java from '../images/java.png';
import Javascript from '../images/javascript.png';
import MySql from '../images/mysql.png';
import Php from '../images/PHP.png';
import Python from '../images/Python.jpeg';
import ReactLogo from '../images/React.png';
import HoneyComb from "./HoneyComb";

export default function Skills() 
{
    return(
        <>
        <div className="outline" id="skills">
            <div className="row row1">
                <HoneyComb src={Python} desc={'90%'} alt={"python"}/>
                <HoneyComb src={Java} desc={"90%"} alt={"Java"}/>
                <HoneyComb src={PyTorch} desc={"65%"} alt={"Pytorch"}/>
            </div>
            <div className="row row2">
                <HoneyComb src={C} desc={"65%"} alt={"C"}/>
                <HoneyComb src={Javascript} desc={"80%"} alt={"javascript"}/>
                <HoneyComb src={Cuda} desc={"70%"} alt={"cuda"}/>
            </div>
            <div className="row row3">
                <HoneyComb src={Php} desc={"65%"} alt={"php"}/>
                <HoneyComb src={ReactLogo} desc={"80%"} alt={"react"}/>
                <HoneyComb src={MySql} desc={"80%"} alt={"mysql"}/>
            </div>
            

         </div>
        </>
    )
}