import React, {useState, useEffect, useRef} from "react";
import '../styles/Header.css'
export default function Header(){
    

    const [toggle, setToggle] = useState(0);
    const [headerClass, setHeaderClass] = useState("");
    const [hamburgerClass, setHamburgerClass] = useState("hide");

    const mediaQuery = '(max-width: 1024px)';
    let mediaQueryList = useRef(window.matchMedia(mediaQuery).matches);
    
    const showLinks = () => {
        var lines = document.querySelectorAll('.lines');
        var header = document.getElementById("header");
    
        if(toggle){
            lines[1].setAttribute("style", "display: inline-block;");
            lines[0].setAttribute("style", "transform: rotate(0) translateY(0) translateX(0);");
            lines[2].setAttribute("style", "transform: rotate(0) translateY(0) translateX(0)");
            header.setAttribute("class", "header-hide");

            setToggle(0);
        }
        else{
            lines[1].setAttribute("style", "display: none;");
            lines[0].setAttribute("style", "transform: rotate(45deg) translateY(8px) translateX(6px); width: 100%");
            lines[2].setAttribute("style", "transform: rotate(-45deg) translateY(-6px) translateX(5px)");
            header.removeAttribute("style");
            header.setAttribute("class", "header-show");
            setToggle(1);
        }

    }


    useEffect(() => {

        window.addEventListener('resize', () => {

            mediaQueryList.current = window.matchMedia(mediaQuery).matches;

            if (mediaQueryList.current) {
                console.log();
                setHeaderClass('hide');
                setHamburgerClass("hamburger");
                
            } else {
                setHamburgerClass("hide");
                setHeaderClass("");
            }
        })

    }, [headerClass, hamburgerClass]);

    useEffect(() => {

        if (mediaQueryList.current) {
            setHeaderClass('hide');
            setHamburgerClass("hamburger");
            
        } else {
            setHeaderClass("");
            setHamburgerClass("hide");
        }

    }, []);

    const handleLinkClick = (key) => {
        if(key < 0){
            var home = document.querySelector('#about-cntr');
            home.scrollIntoView({behavior: "smooth", block: 'center'});
        }
        else{
            let links = document.querySelectorAll('.divider');
            var pos = links[key];
            pos.scrollIntoView({behavior: "smooth", block: 'center'});
        }
        

    }
    
    return(
        <>
            <div className={hamburgerClass} onClick={showLinks}>
                <div className="lines"></div>
                <div className="lines"></div>
                <div className="lines"></div>
            </div>

            <div id="header" className={headerClass}>
                <span className="link" onClick={(e) => handleLinkClick(-1, e)}>Home</span>
                <span className="link" onClick={(e) => handleLinkClick(0, e)}>Skills</span>
                <span className="link" onClick={(e) => handleLinkClick(1, e)}>Projects</span>
                <span className="link" onClick={(e) => handleLinkClick(2, e)}>Contact</span>
            </div>
        </>
    )
}