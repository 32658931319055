
import React from 'react';
import './App.css';
import About from './Components/About';
import Header from './Components/Header';
import Skills from './Components/Skills';
import Divider from './Components/Divider';
import Projects from './Components/Projects';
import Contact from './Components/Contact';

function App() {
  
  return (
    <div className="App">

      <Header />
      <About/>
      <Divider divider={"Skills"}/>
      <Skills />
      <Divider divider={"Projects"} />
      <Projects />
      <Divider divider={"Contact"} />
      <Contact />
    </div>
  );
}

export default App;
