import React, {useState} from 'react'
import '../styles/HoneyComb.css';
export default function HoneyComb(props) 
{

  const [hcState, setHCState] = useState(0);
  const [className, setClassName] = useState("desc-cntr hide");

  

  const displayDesc = () => {

    if(hcState){

      setClassName("desc-cntr hide");
      setHCState(0);

    }
    else{

      setClassName("desc-cntr show");
      setTimeout(() => {
        setClassName(e => e + " door");
      }, 100)
      setHCState(1);
      
    }
  
  }

  


  return (
    <div className="honey-comb" onClick={displayDesc}>

      <img src={props.src} className="honey-comb-img" alt={props.alt}></img>

      <div className={className} id='honey-cmb'>

        <span className='desc'>{props.desc}</span>

      </div>

    </div>
  )
}

